<template>
  <div class="un-found-page">
    <h1>Website Privacy Policy</h1>
    <p class="tc_tittle">1. Your acceptance of these terms</p>
    <p>1.1 By using this Site, Consumers signify acceptance of this policy.</p>
    <p>
      1.2 If Consumers do not agree to this policy, please do not engage our
      services.
    </p>
    <p>
      1.3 Consumers’ continued use of LPMAC Resident following the posting of
      changes to this policy will be deemed your acceptance of those changes.
    </p>
    <p class="tc_tittle">2. Personal identification information</p>
    <p>
      2.1 LPMAC Resident may collect personal identification information from
      Consumers in a variety of ways,
    </p>
    <p>2.2 The variety of ways includes, but not limited to:</p>
    <p>i. when Consumers visit our website;</p>
    <p>ii. register on the website,</p>
    <p>iii. place an order,</p>
    <p>iv. fill out a form; and</p>
    <p>
      v. in connection with other activities, services, features or resources we
      make available on our website.
    </p>
    <p>
      2.3 Consumers may be asked for their name, date of birth, credit card
      information, email address, phone number or other personal identification
      information.
    </p>
    <p>
      2.4 Consumers may, however, visit our website anonymously. We will collect
      personal identification information from consumers only if they
      voluntarily submit such information.
    </p>
    <p>
      2.5 Consumers may interact with LPMAC Resident anonymously and refuse to
      supply personally identification information. Corresponding anonymously
      may prevent Consumers from engaging in certain services provided by LPMAC
      Resident.
    </p>
    <p>
      2.6 Consumers may provide personal information of his or her related
      parties to LPMAC Resident in order to use and receive LPMAC Resident’s
      products and services.
    </p>
    <p class="tc_tittle">3. Non-personal identification information</p>
    <p>
      3.1 LPMAC Resident may collect non-personal identification information
      about Consumers where our goods and services are engaged.
    </p>
    <p>
      3.2 Non-personal identification information may include the browser name,
      IP address, the type of computer and technical information about Consumers
      refer to information related to the connection to our website; that is,
      the operating system and the Internet service providers utilised and other
      similar information.
    </p>
    <p class="tc_tittle">4. Web browser cookies</p>
    <p>4.1 Our website may use “cookies” to enhance Consumer experience.</p>
    <p>
      4.2 Consumer’s web browser places cookies on their hard drive for
      record-keeping purposes and sometimes to track information about them.
    </p>
    <p>
      4.3 Consumers may choose to set their web browser to refuse cookies, or to
      alert you when cookies are being sent. If they do so, note that some parts
      of the website may not function properly.
    </p>
    <p class="tc_tittle">5. How we use collected information</p>
    <p>
      5.1 LPMAC Resident may collect and use Consumers’ personal information for
      the following purposes which are reasonably connected to the functions and
      activities of LPMAC Resident:
    </p>
    <p>i. to improve customer service;</p>
    <p>
      ii. to assist LPMAC Resident in responding to customer service requests
      and support needs;
    </p>
    <p>iii. to personalise user experience</p>
    <p>iv. to improve product services</p>
    <p>
      v. to understand how Consumers, as a group, use LPMAC Resident’s services
      and resources
    </p>
    <p>vi. to run a promotion, contest, survey or other website features</p>
    <p>
      vii. to send Consumers information they agreed to receive in relation to
      topics LPMAC Resident think are of interest to them; and
    </p>
    <p>viii. to administer contests, surveys and/or other similar programs.</p>
    <p class="tc_tittle">
      6. In addition, LPMAC Resident may use Consumer’s information rendered for
      the following:
    </p>
    <p>
      6.1 It may be used to respond to Consumer’s inquiries, questions, and/or
      other requests.
    </p>
    <p>
      6.2 Verification of Identities. To verify identity, manage move in/out
      bookings, amenity events and to determine appropriate services.
    </p>
    <p class="tc_tittle">7. How we protect your information</p>
    <p>
      7.1 LPMAC Resident adopt appropriate data collection, storage and
      processing practices and security measures to protect against unauthorised
      access, alteration, disclosure or destruction of your personal
      information, username, password, transaction information and data stored
      on our Site.
    </p>
    <p class="tc_tittle">8. Sharing your personal information</p>
    <p>
      8.1 LPMAC Resident do not sell, trade, or rent Users personal
      identification information to others including foreign recipients.
    </p>
    <p>
      8.2 LPMAC Resident may share generic aggregated demographic information
      not linked to any personal identification information regarding visitors
      and users with our business partners, trusted affiliates and advertisers
      for the purposes outlined above.
    </p>
    <p>
      8.3 LPMAC Resident may use third party service providers to help us
      operate our business and the website or administer activities on our
      behalf, such as sending out notification of system maintenance.
    </p>
    <p>
      8.4 LPMAC Resident may share Consumers’ information with these third
      parties for those limited purposes provided that you have given us your
      permission.
    </p>
    <p>
      8.5 Notwithstanding the above, LPMAC Resident may give access to personal
      information if so permitted or obliged to by law.
    </p>
    <p class="tc_tittle">9. Third party websites</p>
    <p>
      9.1 Consumers may find advertising or other content on our website that
      link to the sites and services of our partners, suppliers, advertisers,
      sponsors, licensors and other third parties.
    </p>
    <p>
      9.2. LPMAC Resident do not control the content or links that appear on
      these sites and are not responsible for the practices employed by websites
      linked to or from our Site.
    </p>
    <p>
      9.3 The websites or services, including their content and links, may be
      constantly changing.
    </p>
    <p>
      These websites and services may have their own privacy policies and
      customer service policies. Browsing and interaction on any other website,
      including websites which have a link to our website, is subject to that
      website’s own terms and conditions.
    </p>
    <p class="tc_tittle">10. Changes to this privacy policy</p>
    <p>
      10.1 Leon’s Property Maintenance and Care Pty Ltd has the discretion to
      update this privacy policy at any time.
    </p>
    <p>
      10.2 When the privacy policy is updated, LPMAC Resident will revise the
      updated date at our website.
    </p>
    <p>
      10.3 LPMAC Resident encourage Consumers to frequently check the website
      for any changes to stay informed about how LPMAC Resident are helping to
      protect the personal information we collect.
    </p>
    <p>
      10.4 Consumers acknowledge and agree that it is your responsibility to
      review this privacy policy periodically and become aware of modifications.
    </p>
    <p class="tc_tittle">
      11. Access to Personal Information and Quality of Personal Information
    </p>
    <p>
      11.1 If requested to do so, LPMAC Resident will provide Consumers access
      to his or her own personal information we hold about the Consumer. We will
      confirm the Consumer’s identity before giving access to such information.
    </p>
    <p>
      11.2 To ensure that the personal information LPMAC Resident collected is
      accurate, up to date and complete, LPMAC Resident will promptly update new
      personal information to existing records.
    </p>
    <p class="tc_tittle">
      12. If there are any changes to the Consumer’s personal information,
      please get them updated as soon as possible.
    </p>
    <p class="tc_tittle">13. Complaints</p>
    <p>
      13.1 If Consumers wish to complain to us about how LPMAC Resident have
      handled your personal information, you may lodge a complaint to us in
      writing.
    </p>
    <p>
      13.2 We endeavour to respond to your complaint as soon as possible upon
      investigation.
    </p>
    <p class="tc_tittle">Contacting us</p>
    <p>
      If you have any questions about this Privacy Policy, the practices of this
      website, or your dealings with this website, please contact us at
      info@lpmac.com.au.
    </p>
    <p class="tc_tittle">Privacy Policy</p>
    <p>
      This Privacy Policy governs the manner in which Leon’s Property
      Maintenance and Care Pty Ltd (ACN 149 342 085) trading as LPMAC Resident
      collects, uses, maintains and discloses information collected from users
      of the www.lpmac.com.au website (“Site and its subdomains”).
    </p>
    <p>
      This privacy policy applies to the website and all products and services
      offered by Leon’s Property Maintenance and Care Pty Ltd
    </p>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "UnFound",
  data() {
    return {};
  },
  computed: {
    ...mapState("bar", {
      nav: "navBarShow",
      side: "sideBarShow",
    }),
  },
  created() {
    this.backup = { nav: this.nav, side: this.side };
    // 切换side
    this.navBarShow(false);
    this.sideBarShow(false);
  },
  destroyed() {
    this.navBarShow(this.backup.nav);
    this.sideBarShow(this.backup.side);
  },
  methods: {
    ...mapMutations("bar", ["navBarShow", "sideBarShow"]),
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
